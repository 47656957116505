import { PlusOutlined } from '@ant-design/icons';
import { Form, Input, InputNumber, Modal, Select, Upload, type UploadFile } from 'antd';
import type { RcFile } from 'antd/es/upload';
import React, { useState } from 'react';

export const EditableModal = () => {
  const [reason, setReason] = useState<string>('');
  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <Modal
      title="新增明细"
      open={false}
      width={600}
    >
      <Form
        className="pt-4"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item label="收支明细">
          <Select
            options={[
              {
                label: '哈哈哈哈',
                value: 123,
              },
            ]}
            value={reason}
          />
        </Form.Item>
        <Form.Item label="金额">
          <InputNumber
            max="100000000"
            suffix="元"
            step="0.01"
            controls={false}
            value={reason}
          />
        </Form.Item>
        <Form.Item label="明细内容">
          <Input.TextArea
            maxLength={200}
            showCount={true}
            autoSize={{ minRows: 4 }}
            value={reason}
          />
        </Form.Item>
        <Form.Item label="明细内容">
          <Upload
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture-card"
            onPreview={onPreview}
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>上传</div>
            </div>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};
