import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { MainSubStructure } from '../../../utils';
import { EditableModal } from './editableModal';
import { Model } from './model';

const InterviewAdmin = observer(() => {
  const store = useMemo(() => new Model(), []);
  const { mainSubStructureModel } = store;

  return (
    <div className="bg-[#F0F3F8] h-screen flex flex-col">
      <div className="flex-1 bg-white mt-2 pt-2">
        <MainSubStructure store={mainSubStructureModel} />
      </div>

      <EditableModal />
    </div>
  );
});
export default InterviewAdmin;
