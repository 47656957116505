import { action, observable } from 'mobx';
import {
  type BaseData,
  type IMainSubStructureModel,
  MainSubStructureModel,
  NormalProgrammeParams,
} from '../../../utils';
import { NormalProgramme, SearchListModal, request } from '../../../utils';

export class Model {
  @observable public chooseUserModal = false;
  @observable public reasonEditModal = false;
  @observable public tagsModal = false;

  public tagsList = [
    {
      title: '切片分类标签1',
      tags: ['标签1', '标签2', '标签3', '标签4', '标签5', '标签6', '标签7'],
    },
    {
      title: '切片分类标签2',
      tags: ['标签1', '标签2', '标签3', '标签4', '标签5', '标签6'],
    },
    {
      title: '切片分类标签3',
      tags: ['标签1', '标签2', '标签3', '标签4', '标签5'],
    },
  ];

  public filterSet: NormalProgramme = new NormalProgramme({
    filterItems: [
      {
        type: 'select',
        span: 6,
        field: 'nickName',
        label: '收支名称',
        placeholder: '请选择',
      },
      {
        type: 'select',
        span: 6,
        field: 'userName',
        label: '收支类型',
        placeholder: '请选择',
      },
      {
        type: 'select',
        span: 6,
        field: 'mobile',
        label: '创建方式',
        placeholder: '请输入',
      },
      {
        type: 'dateRange',
        span: 6,
        field: 'createTime',
        label: '创建时间',
      },
    ],
  });

  public mainSubStructureModel: IMainSubStructureModel = new MainSubStructureModel({
    buttons: [
      {
        text: '调整到面次数',
        handleClick: () => {},
      },
    ],
    pageId: `${new URLSearchParams(window.location.search)?.get('pageId')}chooseUser`,
    grid: {
      columns: [
        {
          key: 'id',
          name: '雇主名称',
        },
        {
          key: 'userName',
          name: '公司名称',
        },
        {
          key: 'nickName',
          name: '招聘管家',
        },
        {
          key: 'mobile',
          name: '订阅信息',
        },
        {
          key: 'mobile2',
          name: '上线职位数',
        },
        {
          key: 'mobile3',
          name: '到面次数/赠送次数',
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      showPager: true,
      showGridOrderNo: false,
    },
    hiddenSubTable: true,
    api: {
      onQuery: (params) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };

        for (const k in postParams) {
          if (Array.isArray(postParams[k])) {
            postParams[k] = postParams[k].toString();
          }
        }
        if (postParams.createTime) {
          postParams.createTimeBegin = postParams.createTime.split(',')[0];
          postParams.createTimeEnd = postParams.createTime.split(',')[1];
          postParams.createTime = undefined;
        }
        return request<BaseData<any>>({
          url: '',
          method: 'POST',
          data: { ...postParams },
        });
      },
    },
  });

  @action public onChangeModal = (key: string, visible: boolean) => {
    this[key] = visible;
  };
}
